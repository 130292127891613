export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        iconClass: 'entypo entypo-monitor',
      },
    },
    {
      displayName: 'menu.location.label',
      meta: {
        iconClass: 'entypo entypo-compass',
      },
      disabled: true,
      children: [
        {
          name: 'regionsIndex',
          displayName: 'menu.location.regions',
          meta: {
            module: 1,
            permission: {
              controller: 'Regions',
              action: 'index',
            },
            iconClass: 'amicon-region',
          },
        },
        {
          name: 'countriesIndex',
          displayName: 'menu.location.countries',
          meta: {
            module: 2,
            permission: {
              controller: 'Countries',
              action: 'index',
            },
            iconClass: 'amicon-pais',
          },
        },
        {
          name: 'districtsIndex',
          displayName: 'menu.location.districts',
          meta: {
            module: 3,
            permission: {
              controller: 'Districts',
              action: 'index',
            },
            iconClass: 'amicon-distrito',
          },
        },
      ],
    },
    {
      displayName: 'menu.teams.label',
      meta: {
        iconClass: 'entypo entypo-user-add',
      },
      disabled: true,
      children: [
        {
          name: 'prayersIndex',
          displayName: 'menu.prayers.index',
          meta: {
            module: 5,
            permission: {
              controller: 'PrayerRequests',
              action: 'index',
            },
            iconClass: 'entypo entypo-megaphone',
          },
        },
        {
          name: 'teamsIndex',
          displayName: 'menu.teams.index',
          meta: {
            module: 6,
            permission: {
              controller: 'Teams',
              action: 'index',
            },
            iconClass: 'entypo entypo-users',
          },
        },
        {
          name: 'districtPlansIndex',
          displayName: 'menu.teams.district_plans',
          meta: {
            module: 7,
            permission: {
              controller: 'DistrictPlans',
              action: 'index',
            },
            iconClass: 'entypo entypo-book-open',
          },
        },
        {
          name: 'formsIndex',
          displayName: 'menu.teams.forms',
          meta: {
            permission: {
              controller: 'DynamicForms',
              action: 'index',
            },
            iconClass: 'entypo entypo-doc-text',
          },
        },
      ],
    },
    {
      displayName: 'menu.reports.label',
      meta: {
        iconClass: 'amicon-presentaciones',
      },
      children: [
        {
          name: 'reportsIndex',
          displayName: 'menu.reports.presentations',
          meta: {
            permission: {
              controller: 'Proyections',
              action: 'index',
            },
            iconClass: 'entypo entypo-chart-line',
          },
        },
        {
          name: 'preachingsIndex',
          displayName: 'menu.preaching.index',
          meta: {
            permission: {
              controller: 'PreachingPoints',
              action: 'index',
            },
            iconClass: 'entypo entypo-location',
          },
        },
        {
          name: 'mtiIndex',
          displayName: 'menu.reports.mti',
          meta: {
            module: 8,
            permission: {
              controller: 'Mti',
              action: 'index',
            },
            iconClass: 'amicon-inicio',
          },
        },
        {
          name: 'assignmentsIndex',
          displayName: 'menu.reports.assignments',
          meta: {
            module: 9,
            permission: {
              controller: 'Proyections',
              action: 'assignments',
            },
            iconClass: 'amicon-asignaciones',
          },
        },
        {
          name: 'investmentsIndex',
          displayName: 'menu.reports.investments',
          meta: {
            module: 9,
            permission: {
              controller: 'ProyectionInvestments',
              action: 'index',
            },
            iconClass: 'entypo entypo-chart-area',
          },
        },
      ],
    },
    {
      displayName: 'menu.members.label',
      meta: {
        iconClass: 'entypo entypo-vcard',
      },
      children: [
        {
          name: 'notificationsIndex',
          displayName: 'menu.members.notifications',
          meta: {
            permission: {
              controller: 'Notifications',
              action: 'index',
            },
            iconClass: 'entypo entypo-comment',
          },
        },
        {
          name: 'usersIndex',
          displayName: 'menu.members.users',
          meta: {
            permission: {
              controller: 'Users',
              action: 'index',
            },
            iconClass: 'entypo entypo-user',
          },
        },
        {
          name: 'groupsIndex',
          displayName: 'menu.members.groups',
          meta: {
            permission: {
              controller: 'Groups',
              action: 'index',
            },
            iconClass: 'amicon-grupos',
          },
        },
      ],
    },
    {
      displayName: 'menu.trainings.label',
      meta: {
        iconClass: 'entypo entypo-graduation-cap',
      },
      children: [
        {
          name: 'trainingsIndex',
          displayName: 'menu.trainings.label',
          meta: {
            module: 4,
            permission: {
              controller: 'DcpiTrainings',
              action: 'index',
            },
            iconClass: 'amicon-dcpi',
          },
        },
        {
          name: 'rolesIndex',
          displayName: 'menu.trainings.roles',
          meta: {
            module: 10,
            permission: {
              controller: 'DcpiRoles',
              action: 'index',
            },
            iconClass: 'amicon-roles',
          },
        },
        {
          name: 'trainingTypesIndex',
          displayName: 'menu.trainings.training_types',
          meta: {
            module: 4,
            permission: {
              controller: 'TrainingTypes',
              action: 'index',
            },
            iconClass: 'entypo entypo-list-add',
          },
        },
      ],
    },
    {
      name: 'resourcesIndex',
      displayName: 'menu.resources',
      meta: {
        module: 11,
        iconClass: 'entypo entypo-book',
      },
    },
    {
      displayName: 'menu.inventory.label',
      meta: {
        iconClass: 'entypo entypo-flashlight',
      },
      children: [
        {
          name: 'inventoryRequestsIndex',
          displayName: 'menu.inventory.inventory_request',
          meta: {
            module: 12,
            permission: {
              controller: 'InventoryRequests',
              action: 'index',
            },
            iconClass: 'entypo entypo-chat',
          },
        },
      ],
    },
    {
      displayName: 'menu.settings.label',
      meta: {
        iconClass: 'entypo entypo-database',
      },
      children: [
        {
          name: 'goalsIndex',
          displayName: 'menu.settings.goals',
          meta: {
            permission: {
              controller: 'Goals',
              action: 'index',
            },
            iconClass: 'entypo entypo-flag',
          },
        },
        {
          name: 'evangelisticMethodsIndex',
          displayName: 'menu.settings.evangelistic_methods',
          meta: {
            permission: {
              controller: 'EvangelisticMethods',
              action: 'index',
            },
            iconClass: 'entypo entypo-sound',
          },
        },
        {
          name: 'parametersIndex',
          displayName: 'menu.configurations.parameters',
          meta: {
            permission: {
              controller: 'Parameters',
              action: 'index',
            },
            iconClass: 'entypo entypo-tools',
          },
        },
      ],
    },
    {
      displayName: 'menu.configurations.label',
      meta: {
        iconClass: 'entypo entypo-cog',
      },
      children: [
        {
          name: 'themesIndex',
          displayName: 'menu.configurations.themes',
          meta: {
            permission: {
              controller: 'Themes',
              action: 'index',
            },
            iconClass: 'entypo entypo-droplet',
          },
        },
        {
          name: 'emailTemplatesIndex',
          displayName: 'menu.configurations.emails',
          meta: {
            permission: {
              controller: 'EmailTemplates',
              action: 'index',
            },
            iconClass: 'entypo entypo-mail',
          },
        },
        {
          name: 'languagesIndex',
          displayName: 'menu.configurations.languages',
          meta: {
            permission: {
              controller: 'Languages',
              action: 'index',
            },
            iconClass: 'entypo entypo-language',
          },
        },
        {
          name: 'logsIndex',
          displayName: 'menu.configurations.logs',
          meta: {
            permission: {
              controller: 'Logs',
              action: 'index',
            },
            iconClass: 'entypo entypo-flow-cascade',
          },
        },
        {
          name: 'importDataIndex',
          displayName: 'menu.configurations.import',
          meta: {
            permission: {
              controller: 'ImportData',
              action: 'index',
            },
            iconClass: 'entypo entypo-upload',
          },
        },
      ],
    },
  ],
}
